import { Container, Row, Col, Accordion, Image } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimney, faKey, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useNavigate  } from "react-router-dom";
import { useState } from "react";
import clsx from 'clsx';
import styles from './Services.module.scss';

const Services = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(true);

  return (
    <>
    <Container className="my-5">
      <h2>Co teraz?</h2>
      <div className={styles["choice"]}>
        <div className={isActive ? styles["active"] : ''} onClick={() => setIsActive(true)}>
          <span>Kupuję</span>
        </div>
        <div className={!isActive ? styles["active"] : ''} onClick={() => setIsActive(false)}>
          <span>Sprzedaję</span>
        </div>
      </div>
      <>
      <Row className={styles["services-container"]}>
            <Col xs={12} className={clsx(styles['inactive'], isActive ? styles['active0'] : '')}>
              <Row>
                <Col className={styles['number']}><div className={styles["bar-first"]}></div><span>1</span></Col>
                <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/agent.svg'} /></Col>
                <Col lg={9}>
                  <h2>Znajdź najlepszego agenta</h2>
                  <p>W naszym biurze dostępni są dla Ciebie specjaliści - którzy przeprowadzą Cię krok po kroku przez cały proces zakupu.  Nasi agenci są ekspertami na lokalnym rynku.</p>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className={clsx(styles['inactive'], isActive ? styles['active1'] : '')}>
            <Row>
                <Col className={styles['number']}><div className={styles["bar"]}></div><span>2</span></Col>
                <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/budget.svg'} /></Col>
                <Col lg={9}>
                  <h2>Porozmawiajmy o Twoich oczekiwaniach</h2>
                  <p>Opowiesz nam o swojej wymarzonej nieruchomości, ustalimy priorytety, omówimy budżet inwestycji. Potrzebujesz finansowania? - skontaktujemy Cię z najlepszymi specjalistami od kredytowania nieruchomości.</p>
                </Col>
            </Row>
            </Col>
            <Col xs={12} className={clsx(styles['inactive'], isActive ? styles['active2'] : '')}>
            <Row>
                <Col className={styles['number']}><div className={styles["bar"]}></div><span>3</span></Col>
                <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/house.svg'} /></Col>
                <Col lg={9}>
                  <h2>Znajdziemy Twoją wymarzoną nieruchomość</h2>
                  <p>Przeanalizujemy dla Ciebie wszystkie dostępne aktualnie nieruchomości. Współpracujemy z wieloma biurami w naszym mieście, będziemy Cię na bieżąco informować, gdy na rynku pojawią się nowe oferty.</p>
                </Col>
              </Row>
              </Col>
            <Col xs={12} className={clsx(styles['inactive'], isActive ? styles['active3'] : '')}>
            <Row>
                <Col className={styles['number']}><div className={styles["bar-last"]}></div><span>4</span></Col>
                <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/contract.svg'} /></Col>
                <Col lg={9}>
                  <h2>Finalizacja zakupu</h2>
                  <p>Wybrałeś swoją wymarzoną nieruchomość! Nasi agenci poprowadzą Cię przez cały proces negocjacji oraz zakupu, abyś otrzymał najlepszą ofertę za swoje pieniądze. Wesprzemy Cię we wszystkich formalnościach.</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={styles["services-container"]}>
            <Col xs={12} className={clsx(styles['inactive'], !isActive ? styles['active0'] : styles['inactive'])}>
                <Row>
                  <Col className={styles['number']}><div className={styles["bar-first"]}></div><span>1</span></Col>
                  <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/agent.svg'} /></Col>
                  <Col lg={9}>
                    <h2>Znajdź najlepszego agenta</h2>
                    <p>W naszym biurze dostępni są dla Ciebie specjaliści - którzy przeprowadzą Cię krok po kroku przez cały proces sprzedaży.  Nasi agenci są ekspertami na lokalnym rynku.</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className={clsx(styles['inactive'], !isActive ? styles['active1'] : styles['inactive'])}>
              <Row>
                  <Col className={styles['number']}><div className={styles["bar"]}></div><span>2</span></Col>
                  <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/value.svg'} /></Col>
                  <Col lg={9}>
                    <h2>Ustalmy wartość nieruchomości</h2>
                    <p>Skorzystaj z naszego doświadczenia, aby szybko sprawdzić aktualną wartość nieruchomości. W wymagających przypadkach skorzystamy z asysty rzeczoznawcy.</p>
                  </Col>
              </Row>
              </Col>
              <Col xs={12} className={clsx(styles['inactive'], !isActive ? styles['active2'] : styles['inactive'])}>
              <Row>
                  <Col className={styles['number']}><div className={styles["bar"]}></div><span>3</span></Col>
                  <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/forsale.svg'} /></Col>
                  <Col lg={9}>
                    <h2>Wystawienie oferty</h2>
                    <p>Wykonamy profesjonalne sesje zdjęciowe, wirtualny spacer, nalot dronem, oznaczymy nieruchomość. W naszym działaniu łączymy tradycyjne metody ogłaszania ofert z wszystkimi nowymi mediami - posiadamy profile sprzedażowe na wszystkich popularnych platformach.</p>
                  </Col>
                </Row>
                </Col>
                <Col xs={12} className={clsx(styles['inactive'], !isActive ? styles['active3'] : styles['inactive'])}>
              <Row>
                  <Col className={styles['number']}><div className={styles["bar-last"]}></div><span>4</span></Col>
                  <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/sold.svg'} /></Col>
                  <Col lg={9}>
                    <h2>Sprzedaj nieruchomość</h2>
                    <p>Działamy szybko i dbamy o to aby uzyskać dla Ciebie najlepszą cenę. Nasi agenci poprowadzą Cię przez cały proces negocjacji oraz sprzedaży. Wesprzemy Cię we wszystkich formalnościach.</p>
                  </Col>
                </Row>
              </Col>
          </Row>

          </>

      {/*
        isActive 

        ? 
          <Row className={styles["services-container"]}>
            <Col xs={12} className={clsx(styles['inactive'], isActive ? styles['active'] : '')}>
              <Row>
                <Col className={styles['number']}><div className={styles["bar-first"]}></div><span>1</span></Col>
                <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/agent.svg'} /></Col>
                <Col lg={9}>
                  <h2>Znajdź najlepszego agenta</h2>
                  <p>W naszym biurze dostępni są dla Ciebie specjaliści - którzy przeprowadzą Cię krok po kroku przez cały proces zakupu.  Nasi agenci są ekspertami na lokalnym rynku.</p>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className={clsx(styles['inactive'], isActive ? styles['active1'] : '')}>
            <Row>
                <Col className={styles['number']}><div className={styles["bar"]}></div><span>2</span></Col>
                <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/budget.svg'} /></Col>
                <Col lg={9}>
                  <h2>Porozmawiajmy o Twoich oczekiwaniach</h2>
                  <p>Opowiesz nam o swojej wymarzonej nieruchomości, ustalimy priorytety, omówimy budżet inwestycji. Potrzebujesz finansowania? - skontaktujemy Cię z najlepszymi specjalistami od kredytowania nieruchomości.</p>
                </Col>
            </Row>
            </Col>
            <Col xs={12} className={clsx(styles['inactive'], isActive ? styles['active2'] : '')}>
            <Row>
                <Col className={styles['number']}><div className={styles["bar"]}></div><span>3</span></Col>
                <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/house.svg'} /></Col>
                <Col lg={9}>
                  <h2>Znajdziemy Twoją wymarzoną nieruchomość</h2>
                  <p>Przeanalizujemy dla Ciebie wszystkie dostępne aktualnie nieruchomości. Współpracujemy z wieloma biurami w naszym mieście, będziemy Cię na bieżąco informować, gdy na rynku pojawią się nowe oferty.</p>
                </Col>
              </Row>
              </Col>
            <Col xs={12} className={clsx(styles['inactive'], isActive ? styles['active3'] : '')}>
            <Row>
                <Col className={styles['number']}><div className={styles["bar-last"]}></div><span>4</span></Col>
                <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/contract.svg'} /></Col>
                <Col lg={9}>
                  <h2>Finalizacja zakupu</h2>
                  <p>Wybrałeś swoją wymarzoną nieruchomość! Nasi agenci poprowadzą Cię przez cały proces negocjacji oraz zakupu, abyś otrzymał najlepszą ofertę za swoje pieniądze. Wesprzemy Cię we wszystkich formalnościach.</p>
                </Col>
              </Row>
            </Col>
          </Row>
        :
          <Row className={styles["services-container"]}>
            <Col xs={12} className={clsx(styles['inactive'], !isActive ? styles['active'] : styles['inactive'])}>
                <Row>
                  <Col className={styles['number']}><div className={styles["bar-first"]}></div><span>1</span></Col>
                  <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/agent.svg'} /></Col>
                  <Col lg={9}>
                    <h2>Znajdź najlepszego agenta</h2>
                    <p>W naszym biurze dostępni są dla Ciebie specjaliści - którzy przeprowadzą Cię krok po kroku przez cały proces sprzedaży.  Nasi agenci są ekspertami na lokalnym rynku.</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className={clsx(styles['inactive'], !isActive ? styles['active1'] : styles['inactive'])}>
              <Row>
                  <Col className={styles['number']}><div className={styles["bar"]}></div><span>2</span></Col>
                  <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/value.svg'} /></Col>
                  <Col lg={9}>
                    <h2>Ustalmy wartość nieruchomości</h2>
                    <p>Skorzystaj z naszego doświadczenia, aby szybko sprawdzić aktualną wartość nieruchomości. W wymagających przypadkach skorzystamy z asysty rzeczoznawcy.</p>
                  </Col>
              </Row>
              </Col>
              <Col xs={12} className={clsx(styles['inactive'], !isActive ? styles['active2'] : styles['inactive'])}>
              <Row>
                  <Col className={styles['number']}><div className={styles["bar"]}></div><span>3</span></Col>
                  <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/forsale.svg'} /></Col>
                  <Col lg={9}>
                    <h2>Wystawienie oferty</h2>
                    <p>Wykonamy profesjonalne sesje zdjęciowe, wirtualny spacer, nalot dronem, oznaczymy nieruchomość. W naszym działaniu łączymy tradycyjne metody ogłaszania ofert z wszystkimi nowymi mediami - posiadamy profile sprzedażowe na wszystkich popularnych platformach.</p>
                  </Col>
                </Row>
                </Col>
                <Col xs={12} className={clsx(styles['inactive'], !isActive ? styles['active3'] : styles['inactive'])}>
              <Row>
                  <Col className={styles['number']}><div className={styles["bar-last"]}></div><span>4</span></Col>
                  <Col className={styles["icon"]} lg={2}><img src={process.env.PUBLIC_URL + '/sold.svg'} /></Col>
                  <Col lg={9}>
                    <h2>Sprzedaj nieruchomość</h2>
                    <p>Działamy szybko i dbamy o to aby uzyskać dla Ciebie najlepszą cenę. Nasi agenci poprowadzą Cię przez cały proces negocjacji oraz sprzedaży. Wesprzemy Cię we wszystkich formalnościach.</p>
                  </Col>
                </Row>
              </Col>
          </Row>*/
      }
    </Container>
    {/*<Container className={styles["services-container"]} fluid>
      <Row className={styles["icon-container"]}>
        <Col className={styles["icon"]} onClick={() => navigate('/sell')} xs={12} md={4}>
          <FontAwesomeIcon icon={faHouseChimney} />
          <div className={styles["description"]}>
            <h3>Sprzedaj</h3>
            <div className={styles["text-container"]}>
              <p>Kompleksowa obsługa całego procesu sprzedaży. Wirtualny spacer, fotografia dronem, profesjonalny marketing w social media.</p>
            </div>
          </div>
        </Col>
        <Col className={styles["icon"]} onClick={() => navigate('/buy')} xs={12} md={4}>
          <FontAwesomeIcon icon={faKey} />
          <div className={styles["description"]}>
            <h3>Kup</h3>
            <div className={styles["text-container"]}>
            <p>Pomożemy Ci wybrać najlepsze rozwiązanie dla Twojej sytuacji. Nie wiesz na co zwracać uwagę? Zabezpieczymy Twoją transakcję zakupu w taki sposób, żebyś mógł być pewien, że Twoje interesy są bezpieczne.</p>
            </div>

          </div>
        </Col>
        <Col className={styles["icon"]} onClick={() => navigate('/about')} xs={12} md={4}>
          <FontAwesomeIcon icon={faCircleInfo} />
          <div className={styles["description"]}>
            <h3>O nas</h3>
            <div className={styles["text-container"]}>
            <p>To dla Ciebie za mało? Sprawdź stronę o nas i dowiedz się więcej.</p>
            </div>

          </div>
        </Col>
      </Row>
  </Container>*/}
  </>
)
}

export default Services;