import styles from './SellProcess.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import clsx from 'clsx';

const SellProcess = () => {
    return (
        <>
        <Container className={styles['about-container']} fluid>
          <h2>Kompleksowe podejście</h2>
          <h3>Obsługa sprzedaży nieruchomości</h3>
          <Row>
            <Col xs={12} md={6} lg={5} className={styles['social-image']}>
              <Image src={process.env.PUBLIC_URL + '/meeting.png'} fluid />
            </Col>
            <Col xs={12} md={6} lg={7}>
                <p>
                W naszym biurze nieruchomości oferujemy kompleksową obsługę sprzedaży, która
                obejmuje każdy etap procesu, od przygotowania nieruchomości do sprzedaży, aż po
                finalizację transakcji. Naszym celem jest zapewnienie klientom wsparcia na
                każdym kroku, aby sprzedaż była szybka, efektywna i korzystna.              
                </p>
                <p>
                Oferujemy wsparcie w przygotowaniu nieruchomości do sprzedaży, w tym doradztwo
                w zakresie home stagingu, które polega na profesjonalnym aranżowaniu wnętrz,
                aby przyciągnąć uwagę potencjalnych nabywców
                </p>
                <p> 
                Skuteczny marketing to klucz do szybkiej sprzedaży nieruchomości. Wykorzystujemy
                zaawansowane narzędzia marketingowe, takie jak fotografia sferyczna, filmy z drona
                oraz wirtualne spacery, aby wyróżnić ofertę na rynku. Prowadzimy kampanie w
                mediach społecznościowych, na platformach takich jak Facebook, Instagram i
                TikTok, aby dotrzeć do szerokiego grona potencjalnych nabywców.
                </p>
            </Col>
          </Row>
        </Container>
        <Container className={clsx(styles['about-container'], styles['right-side'])} fluid>
          <h2>Jesteśmy dla Ciebie przez cały czas</h2>
          <h3>Wsparcie na każdym etapie</h3>
          <Row>
            <Col xs={12} md={6} lg={7}>
                <p>
                Nasze biuro zapewnia profesjonalne prezentacje nieruchomości, oraz indywidualne spotkania z potencjalnymi kupcami. Nasi agenci
                nieruchomości są gotowi odpowiadać na pytania, udzielać szczegółowych informacji
                oraz przedstawiać nieruchomość w jak najlepszym świetle.              </p>
                <p>
                Poprowadzimy negocjacje w imieniu klientów, dążąc do uzyskania najlepszych
                możliwych warunków sprzedaży. Dbamy, aby proces sprzedaży był zgodny z
                przepisami i przebiegał bezproblemowo.              
                </p>
                <p>
                Zapewniamy pełne wsparcie w finalizacji transakcji, w tym pomoc w przygotowaniu i
                podpisaniu umowy sprzedaży. Współpracujemy z notariuszami oraz kancelariami
                prawnymi, aby zagwarantować bezpieczeństwo i legalność każdej transakcji.    
                </p>
                <p>
                Po zakończeniu sprzedaży oferujemy wsparcie posprzedażowe, w tym pomoc w
                załatwieniu wszelkich formalności związanych z przekazaniem nieruchomości.
                Dbamy o to, aby cały proces zakończył się pełnym zadowoleniem naszych klientów.    
                </p>
            </Col>
            <Col xs={12} md={6} lg={5} className={styles['social-image']}>
              <Image src={process.env.PUBLIC_URL + '/deal.png'} fluid />
            </Col>
          </Row>
        </Container>
        <Container className={styles['info-container']}>
          <h2>Jak wygląda cały proces?</h2>
          <h3>Zazwyczaj przebiega on w ten sposób:</h3>
          <Row>
            <Col className={styles['info']} xs={12} sm={6} lg={4}>
              <div className={styles['number']}>1</div>
              <h6>Profesjonalna wycena nieruchomości</h6> <p>Musimy razem zastanowić się jakie są Twoje oczekiwania finansowe i skonfrontować to z obecną sytuacją na rynku.</p>
            </Col>
            <Col className={styles['info']} xs={12} sm={6} lg={4}>
              <div className={styles['number']}>2</div>
              <h6>Przygotowanie nieruchomości do sprzedaży</h6> <p>Znamy cenę? Świetnie. Teraz należy właściwie zaaranżować nieruchomość do sesji fotograficznej. Spokojnie, wszystko zrobimy za Ciebie.</p>
            </Col>
            <Col className={styles['info']} xs={12} sm={6} lg={4}>
              <div className={styles['number']}>3</div>
              <h6>Skuteczny marketing i promocja</h6><p>Tutaj dzieje się prawdziwa magia i na tym etapie zaczynamy docierać do potencjalnych klientów. Skoro to czytasz, to znaczy, że jesteśmy skuteczni.
              </p>
            </Col>
            <Col className={styles['info-bottom']} xs={12} sm={6} lg={4}>
              <div className={styles['number']}>4</div>
              <h6>Profesjonalna prezentacja</h6><p>Ogłoszenie to nie wszystko, każda nieruchomość potrzebuje właściwej prezentacji. Zazwyczaj na tym etapie ktoś odkrywa, że to miejsce dla niego.</p>
            </Col>
            <Col className={styles['info-bottom']} xs={12} sm={6} lg={4}>
              <div className={styles['number']}>5</div>
              <h6>Negocjacje i doradztwo</h6><p>Trochę stresujące, to prawda. Dlatego ten cieżar również ściągamy z Twoich barków. Na pewno nie zostaniesz z tym sam.</p>
            </Col>
            <Col className={styles['info-bottom']} xs={12} sm={6} lg={4}>
              <div className={styles['number']}>6</div>
              <h6>Finalizacja transakcji</h6><p>Proces przyjemny, ale obarczony dużym ryzkiem. Umowa, notariusz, dokumentacja. Nie musisz nawet wiedzieć od czego zacząć. Wystarczy, że zaczniesz od telefonu do nas!</p>
            </Col>
          </Row>
        </Container>
      </>
      )
}

export default SellProcess;