import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { Form, Button, Container, Modal, Spinner } from "react-bootstrap";
import { addOffer } from "../../../redux/offersReducer";
import { clear, status } from "../../../redux/statusReducer";
import styles from './AddOffer.module.scss';

import { 
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Toolbar,
  EditorProvider,
  Editor,
  BtnBold

} from 'react-simple-wysiwyg';

const AddOffer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: 0,
    pricem2: 0,
    floor: '',
    rooms: 0,
    surface: 0,
    building: 'Mieszkanie',
    market: 'Wtórny',
    year: 0,
    garage: false,
    basement: false,
    rent: false,
    sale: false,
    location: '',
    purpose: 'Budowlana',
    driveway: 'Utwardzony',
    fence: 'Tak',
    shape: 'Regularny',
    media: 'Tak',
    locus: 'Miasto'
  });
console.log(formData.type)
  const [imagesData, setImagesData] = useState({
    images: [],
  });

  const uploadingStatus = useSelector(status);

  const handleClose = () => {
    dispatch(clear());
    navigate('/');
  }
  console.log(formData)
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : (name === 'year' || name === 'price' || name === 'pricem2' || name === 'surface' || name === 'rooms' || name === 'type' ? parseInt(value, 10) : value),
    }));
  };

  const handleChangeImages = (e) => {
    const { name, files } = e.target;
    if (name === 'images') { 
      const imagesArray = Array.from(files);
      setImagesData((prevData) => ({
        ...prevData,
        images: imagesArray,
      }));
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const imagesFormData = new FormData();
  
    const promises = imagesData.images.map(async (photo, index) => {
      imagesFormData.append(`photos`, photo);
    });
  
    await Promise.all(promises);
  
    for (const entry of imagesFormData.entries()) {
    }
    dispatch(addOffer(formData, imagesFormData));
  };

  console.log(formData.description)
  return (
    <Container>
      <Form onSubmit={handleSubmit}>

        <Form.Group controlId="building">
          <Form.Label>Nieruchomość</Form.Label>
          <Form.Group controlId="building">
            <Form.Select
              name="building"
              onChange={handleChange}
            >
                <option value="Mieszkanie">Mieszkanie</option>
                <option value="Dom">Dom</option>
                <option value="Działka">Działka</option>
              </Form.Select>
          </Form.Group>
        </Form.Group>

        <Form.Group controlId="name">
          <Form.Label>Nazwa</Form.Label>
          <Form.Control
            required
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            autoComplete="on"
          />
        </Form.Group>

        <Form.Group controlId="price">
          <Form.Label>Cena</Form.Label>
          <Form.Control
            required
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="pricem2">
          <Form.Label>Cena za m2</Form.Label>
          <Form.Control
            required
            type="number"
            name="pricem2"
            value={formData.pricem2}
            onChange={handleChange}
          />
        </Form.Group>

        {
          formData.building === "Działka" 
          ? 
          <>
            <Form.Group controlId="purpose">
              <Form.Label>Typ działki</Form.Label>
              <Form.Group controlId="purpose">
              <Form.Select
                name="purpose"
                onChange={handleChange}
              >

                  <option value="Budowlana">Budowlana</option>
                  <option value="Rolna">Rolna</option>
                  <option value="Inwestycyjna">Inwestycyjna</option>
                
              </Form.Select>
              </Form.Group>
            </Form.Group>

            <Form.Group controlId="driveway">
              <Form.Label>Dojazd</Form.Label>
              <Form.Group controlId="driveway">
              <Form.Select
                name="driveway"
                onChange={handleChange}
              >

                  <option value="Utwardzony">Utwardzony</option>
                  <option value="Nieutwardzony">Nieutwardzony</option>
                  <option value="Asfaltowy">Asfaltowy</option>
                
              </Form.Select>
              </Form.Group>
            </Form.Group>

            <Form.Group controlId="fence">
              <Form.Label>Ogrodzenie</Form.Label>
              <Form.Group controlId="fence">
              <Form.Select
                name="fence"
                onChange={handleChange}
              >

                  <option value="Tak">Tak</option>
                  <option value="Nie">Nie</option>
                
              </Form.Select>
              </Form.Group>
            </Form.Group>

            <Form.Group controlId="media">
              <Form.Label>Media</Form.Label>
              <Form.Group controlId="media">
              <Form.Select
                name="media"
                onChange={handleChange}
              >

                  <option value="Tak">Tak</option>
                  <option value="Nie">Nie</option>
                
              </Form.Select>
              </Form.Group>
            </Form.Group>

            <Form.Group controlId="shape">
              <Form.Label>Kształt</Form.Label>
              <Form.Group controlId="shape">
              <Form.Select
                name="shape"
                onChange={handleChange}
              >

                  <option value="Regularny">Regularny</option>
                  <option value="Nieregularny">Nieregularny</option>
                
              </Form.Select>
              </Form.Group>
            </Form.Group>

            <Form.Group controlId="locus">
              <Form.Label>Położenie</Form.Label>
              <Form.Group controlId="locus">
              <Form.Select
                name="locus"
                onChange={handleChange}
              >

                  <option value="Miasto">Miasto</option>
                  <option value="Pod miastem">Pod miastem</option>
                  <option value="Wieś">Wieś</option>
                
              </Form.Select>
              </Form.Group>
            </Form.Group>

          </>
          
          :

          <>

            <Form.Group controlId="rooms">
            <Form.Label>Liczba pokoi</Form.Label>
            <Form.Control
              required
              type="number"
              name="rooms"
              value={formData.rooms}
              onChange={handleChange}
            />
            </Form.Group>

            <Form.Group controlId="market">
              <Form.Label>Rynek</Form.Label>
              <Form.Group controlId="market">
                <Form.Select
                  name="market"
                  onChange={handleChange}
                >
                    <option value="Wtórny">Wtórny</option>
                    <option value="Pierwotny">Pierwotny</option>
                  </Form.Select>
              </Form.Group>
            </Form.Group>

            <Form.Group controlId="year">
              <Form.Label>Rok Budowy</Form.Label>
              <Form.Control
                required
                type="number"
                name="year"
                value={formData.year}
                onChange={handleChange}
              />
            </Form.Group>


            <Form.Group controlId="garage">
              <Form.Check
                type="checkbox"
                label="Garaż"
                name="garage"
                value={formData.garage}
                onChange={handleChange}
              />
              <Form.Check
                type="checkbox"
                label="Piwnica"
                name="basement"
                value={formData.basement}
                onChange={handleChange}
              />
              <Form.Check
                type="checkbox"
                label="Wynajem"
                name="rent"
                value={formData.rent}
                onChange={handleChange}
              />
              <Form.Check
                type="checkbox"
                label="Sprzedaż"
                name="sale"
                value={formData.sale}
                onChange={handleChange}
              />

            </Form.Group>

          </>
        
        }


        <Form.Group controlId="location">
              <Form.Label>Lokalizacja</Form.Label>
              <Form.Control
                required
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
              />
        </Form.Group>

        <Form.Group controlId="surface">
          <Form.Label>Powierzchnia</Form.Label>
            <Form.Control
              required
              type="number"
              name="surface"
              value={formData.surface}
              onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="description">
          <Form.Label>Opis</Form.Label>

          <EditorProvider>
              <Editor 
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
              >
              <Toolbar>
              <BtnUndo />
                <BtnRedo />
                <Separator />
                <BtnBold />
                <BtnItalic />
                <BtnUnderline />
                <BtnStrikeThrough />
                <Separator />
                <BtnNumberedList />
                <BtnBulletList />
                <Separator />
                <BtnLink />
                <BtnClearFormatting />
                <HtmlButton />
                <Separator />
                <BtnStyles />
              </Toolbar>
            </Editor>
          </EditorProvider>

        </Form.Group>

        <Form.Group className="my-2">
          <Form.Label>Zdjęcia</Form.Label>
          <Form.Control
            multiple
            type="file"
            name="images"
            accept="image/*"
            onChange={handleChangeImages}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <>
      <Modal
        show={uploadingStatus !== ''}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Dodawanie ogłoszenia</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles["modal"]}>
          {uploadingStatus === 'processing' && <h5>Trwa dodawanie ogłoszenia...</h5>}
          {uploadingStatus === 'failed' && <h5>Wystąpił błąd podczas dodawania ogłoszenia</h5>}
          {uploadingStatus === 'success' && <h5>Ogłoszenie dodane!</h5>}
          {uploadingStatus === 'processing' ? <Spinner /> : <Button onClick={() => handleClose()}>Zamknij</Button>}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
    </Container>
  )
}

export default AddOffer;