import styles from './Footer.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram,faSquareFacebook } from "@fortawesome/free-brands-svg-icons";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
      <Container>

      <Row>
        
        <Col className='d-flex align-items-center' sm={6} lg={2}>
           <img src={process.env.PUBLIC_URL + '/logo_company.png'} />
        </Col>

        <Col className='d-flex flex-column mt-2 mb-2' sm={6} lg={3}>
          <span>MW Nieruchomości sp. z o.o.</span>
          <span>ul. Kombatantów 34/701</span>
          <span>66-400 Gorzów Wielkopolski</span>
        </Col>

        <Col className='d-flex flex-column mt-2 mb-2' sm={6} lg={3}>
          <span><Link to="tel:501785913">501 785 913</Link></span>
          <span><Link to="tel:600044147">600 044 147</Link></span>
          <span><Link to="mailto:biuro@mw.nieruchomosci.pl">biuro@mw.nieruchomosci.pl</Link></span>
        </Col>

        <Col className='d-flex flex-row align-items-center mt-2 mb-2' sm={6} lg={3}>
            <div className={styles["social-icon"]}>
              <Link to="https://www.instagram.com/m.w.nieruchomosci/" target="_blank">
                <FontAwesomeIcon icon={faInstagram} />
              </Link>
            </div>
            <div className={styles["social-icon"]}>
            <Link to="https://www.facebook.com/profile.php?id=61557616326122" target="_blank">
              <FontAwesomeIcon icon={faSquareFacebook} />
            </Link>
            </div>
        </Col>

      </Row>
      </Container>

    </footer>
  );
}

export default Footer;