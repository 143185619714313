import styles from './BuyProcess.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import clsx from 'clsx';

const BuyProcess = () => {
    return (
        <>
        <Container className={styles['about-container']} fluid>
          <h2>Indywidualne podejście</h2>
          <h3>Obsługa zakupu nieruchomości</h3>
          <Row>
            <Col xs={12} md={6} lg={5} className={styles['social-image']}>
              <Image src={process.env.PUBLIC_URL + '/discussion.png'} fluid />
            </Col>
            <Col xs={12} md={6} lg={7}>
                <p>
                  W naszym biurze oferujemy kompleksową obsługę transakcji,
                  która obejmuje każdy etap procesu zakupowego, od wyszukiwania idealnej nieruchomości, aż po
                  finalizację umowy. Naszym celem jest zapewnić Ci pełne
                  wsparcie, aby proces zakupu był płynny, bezpieczny i satysfakcjonujący.                </p>
                <p>
                  Rozpoczynamy od dokładnego zrozumienia Twoich potrzeb i oczekiwań.
                  Nasi doświadczeni doradcy pomagają określić kryteria wyszukiwania,
                  takie jak lokalizacja, budżet, typ nieruchomości i preferencje dotyczące wyposażenia.
                  Dzięki temu możemy przedstawić oferty dopasowane specjalnie dla Ciebie.                
                </p>
                <p> 
                  Wykorzystujemy nasze szerokie kontakty oraz dostęp do rozbudowanych baz
                  danych, aby znaleźć najbardziej atrakcyjne oferty nieruchomości. Regularnie
                  aktualizujemy naszą listę dostępnych nieruchomości, żeby zapewnić Ci
                  najświeższe i najbardziej odpowiednie propozycje.                
                </p>
            </Col>
          </Row>
        </Container>
        <Container className={clsx(styles['about-container'], styles['right-side'])} fluid>
          <h2>Od początku do końca</h2>
          <h3>Jesteśmy z Tobą przez cały czas</h3>
          <Row>
            <Col xs={12} md={6} lg={7}>
                <p>
                  Nasi agenci nieruchomości umawiają spotkania, przygotowują szczegółowe opisy i
                  raporty oraz towarzyszą Ci podczas oględzin, udzielając profesjonalnych
                  porad i odpowiadając na wszystkie pytania.
                </p>
                <p>
                  Oferujemy pełne wsparcie w negocjacjach warunków zakupu, dążąc do
                  uzyskania najkorzystniejszych cen i dbając o bezpieczeństwo Twojej transakcji. Umowy na których działamy są sporządzone 
                  przez kanelarię prawną, tak aby nie dopuścić do jakichkolwiek błędów natury prawnej.                
                </p>
                <p>
                  Po dokonaniu zakupu, oferujemy pomoc w załatwieniu wszelkich formalności związanych z przejęcieciem nieruchomości. 
                  Nasze biuro jest zawsze dostępne, aby odpowiadać na pytania i pomagać w każdej sytuacji
                  po zakupie.
                </p>
            </Col>
            <Col xs={12} md={6} lg={5} className={styles['social-image']}>
              <Image src={process.env.PUBLIC_URL + '/sold.png'} fluid />
            </Col>
          </Row>
        </Container>
        <Container className={styles['info-container']}>
          <h2>Jak wygląda cały proces?</h2>
          <h3>Zazwyczaj przebiega on w ten sposób:</h3>
          <Row>
            <Col className={styles['info']} xs={12} sm={6} lg={4}>
              <div className={styles['number']}>1</div>
              <h6>Analiza Potrzeb i Doradztwo</h6> <p>Zaczynamy od rozmowy, najlepiej przy kawie. Opowiesz nam czego szukasz i jakie masz oczekiwania.</p>
            </Col>
            <Col className={styles['info']} xs={12} sm={6} lg={4}>
              <div className={styles['number']}>2</div>
              <h6>Wyszukiwanie Nieruchomości</h6> <p>Skoro wiemy już czego szukamy, to przystępujemy do pracy. Postaramy się wybrać dla Ciebie dokładnie to czego potrzebujesz.</p>
            </Col>
            <Col className={styles['info']} xs={12} sm={6} lg={4}>
              <div className={styles['number']}>3</div>
              <h6>Oględziny</h6><p>Teraz czas na trochę zwiedzania. Mamy już oferty, teraz pora je sprawdzić. Czekamy na ten moment, gdy wejdziesz i będziesz więdział, że to jest to!</p>
            </Col>
            <Col className={styles['info-bottom']} xs={12} sm={6} lg={4}>
              <div className={styles['number']}>4</div>
              <h6>Negocjacje i umowa wstępna</h6><p>Skoro znaleźliśmy odpowiednie miejsce dla Ciebie, to teraz czas na negocjacje i umowę. Tutaj też nie jesteś sam, nie ma się czym stresować.</p>
            </Col>
            <Col className={styles['info-bottom']} xs={12} sm={6} lg={4}>
              <div className={styles['number']}>5</div>
              <h6>Kruczki prawne</h6><p>Na umowę poświęcamy dwa punkty, dlaczego? Bo to bardzo ważne, podchodzimy do tego naprawdę serio. Ty masz czuć się bezpiecznie, a umowa ma być sprawdzona kilka razy. Chodzi tu o Twoje pieniądze i Twoją przyszłość.</p>
            </Col>
            <Col className={styles['info-bottom']} xs={12} sm={6} lg={4}>
              <div className={styles['number']}>6</div>
              <h6>Przekazanie nieruchomości</h6><p>Chyba najprzyjemniejszy moment. Do tego dążyliśmy. Wszystko się udało, masz już klucze. Czy to koniec? Jesteśmy przekonani, że to dopiero początek!</p>
            </Col>
          </Row>
        </Container>
      </>
      )
}

export default BuyProcess;