import styles from './About.module.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Maps from '../../features/Map/Map';
import clsx from 'clsx';

const About = () => {
  return (
    <>
      <Container className={styles['about-container']} fluid>
        <h2>Nowoczesne podejście</h2>
        <h3>Stawiamy na marketing online</h3>
        <Row>
          <Col xs={12} md={6} lg={5} className={styles['social-image']}>
            <Image src={process.env.PUBLIC_URL + '/SOCIALMEDIA.png'} fluid />
          </Col>
          <Col xs={12} md={6} lg={7}>
            <p>
              Marketing odgrywa kluczową rolę w sprzedaży nieruchomości, ponieważ umożliwia dotarcie 
              do potencjalnych nabywców i zainteresowanie nimi ofertą. Skuteczna strategia marketingowa 
              może wyróżnić nieruchomość spośród konkurencji, zwiększyć jej widoczność na rynku oraz 
              przyciągnąć uwagę klientów, co może skrócić czas sprzedaży i zwiększyć jej wartość. 
              
            </p>
            <p>
              Dzięki różnorodnym narzędziom i technikom marketingowym, można skutecznie komunikować wartość 
              nieruchomości, budować zaufanie klientów oraz zachęcać ich do podjęcia decyzji zakupowej.
            </p>
          </Col>
        </Row>
      </Container>
      <Container className={clsx(styles['about-container'], styles['right-side'])} fluid>
        <h2>Doświadczona załoga</h2>
        <h3>Twardo stąpamy po ziemi</h3>
        <Row>
          <Col xs={12} md={6} lg={7}>
            <p>
            Wiemy, że to marketing odgrywa kluczową rolę w sprzedaży nieruchomości, ponieważ umożliwia
            dotarcie do potencjalnych nabywców i zainteresowanie ich ofertą. Skuteczna strategia marketingowa
            może wyróżnić nieruchomość spośród konkurencji, zwiększyć jej widoczność na rynku oraz
            przyciągnąć uwagę klientów, co może skrócić czas sprzedaży i zwiększyć jej wartość. 
            </p>
            <p>
            W temacie marketingu online czujemy się jak ryba w wodzie, 
            sprawdź nasze realizacje na takich portalach jak Instagram, TikTok, Facebook, Pinterest.  
            </p>
          </Col>
          <Col xs={12} md={6} lg={5} className={styles['social-image']}>
            <Image src={process.env.PUBLIC_URL + '/team.png'} fluid />
          </Col>
        </Row>
      </Container>
      <Container className={styles['info-container']}>
        <h2>Dlaczego akurat my?</h2>
        <h3>Powodów jest mnóstwo, pozwól że podamy Ci tylko te najważniejsze:</h3>
        <Row>
          <Col className={styles['info']} xs={12} sm={6} lg={4}>
            <div className={styles['number']}>1</div>
            <p>Dysponujemy wiedzą ekspercką na temat lokalnego rynku nieruchomości, co ułatwia naszym klientom dokonywanie świadomych decyzji w kwesti ustalania potencjalnej ceny danej</p>
          </Col>
          <Col className={styles['info']} xs={12} sm={6} lg={4}>
            <div className={styles['number']}>2</div>
            <p>Dzięki naszym działaniom marketingowym, skutecznie zwiększamy zasięg i widoczność ofert sprzedaży lub kupna.</p>
          </Col>
          <Col className={styles['info']} xs={12} sm={6} lg={4}>
            <div className={styles['number']}>3</div>
            <p>Możemy skutecznie negocjować warunki transakcji, chroniąc Twoje interesy.</p>
          </Col>
          <Col className={styles['info-bottom']} xs={12} sm={6} lg={4}>
            <div className={styles['number']}>4</div>
            <p>Pomagamy w załatwianiu wszystkich formalności i dokumentów związanych z transakcją, co ułatwia proces sprzedaży lub kupna nieruchomości.</p>
          </Col>
          <Col className={styles['info-bottom']} xs={12} sm={6} lg={4}>
            <div className={styles['number']}>5</div>
            <p>Dysponujemy szeroką siecią kontaktów, możemy szybko znaleźć potencjalnych nabywców lub najemców dla Twojej nieruchomości.</p>
          </Col>
          <Col className={styles['info-bottom']} xs={12} sm={6} lg={4}>
            <div className={styles['number']}>6</div>
            <p>Zapewniamy klientom wsparcie i doradztwo na każdym etapie transakcji, od poszukiwania idealnej nieruchomości do finalizacji umowy.</p>
          </Col>
        </Row>
      </Container>
      <Maps />
    </>
  )
}

export default About;