import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import styles from './Map.module.scss';

const Maps = () => {
  const key = "AIzaSyBHrqLId2gyFL8m6eukd2_WR6Tj6_H7VhA";

  return (    
    <div className={styles["map"]}>
      <APIProvider apiKey={key}>
        <Map
          zoom={15}
          center={{lat: 52.76074814009038, lng: 15.258984915831055}}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
        >
          <Marker position={{lat: 52.76074814009038, lng: 15.258984915831055}} />
        </Map>
    </APIProvider>
    </div>
  )
}

export default Maps;