import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import OfferMiniature from "../../features/OfferMiniature/OfferMiniature";
import { uploadOffers, offersList } from "../../../redux/offersReducer";

const Sell = () => {
  const dispatch = useDispatch();
  const offers = useSelector(offersList);
  /*const offers = [
    {
      id: 'eed5d84a-6e0a-45f5-b76c-ceceabf31332',
      name: 'Atrakcyjne działki budowlne w mieście, ul. Dolna',
      description: '<p style="color: rgb(33, 33, 33); font-family: &quot;Open Sans&quot;, Arial, sans-serif; font-size: 14px;"><br class="Apple-interchange-newline">Oferujemy Państwu atrakcyjne działki położone w Gorzowie Wielkopolskim przy ulicy Dolnej, w idealnym miejscu dla osób ceniących sobie sielski klimat w mieście. Świetny dojazd, zaledwie 5 minut od wjazdu na S3 i 10 minut od Galerii Askana. Do samej nieruchomości dojazd drogą utwardzoną.</p><p style="color: rgb(33, 33, 33); font-family: &quot;Open Sans&quot;, Arial, sans-serif; font-size: 14px;"></p><p style="color: rgb(33, 33, 33); font-family: &quot;Open Sans&quot;, Arial, sans-serif; font-size: 14px;">W promieniu kilometra od działek:<br>- Market oraz sklepy,<br>- Przedszkole,<br>- Kościół,<br>- Przystanki komunikacji miejskiej oraz PKP</p><p style="color: rgb(33, 33, 33); font-family: &quot;Open Sans&quot;, Arial, sans-serif; font-size: 14px;"></p><p style="color: rgb(33, 33, 33); font-family: &quot;Open Sans&quot;, Arial, sans-serif; font-size: 14px;"><b>Dostępne jest 10 działek o powierzchni około 1000 m2.</b></p><p style="color: rgb(33, 33, 33); font-family: &quot;Open Sans&quot;, Arial, sans-serif; font-size: 14px;"></p><p style="color: rgb(33, 33, 33); font-family: &quot;Open Sans&quot;, Arial, sans-serif; font-size: 14px;">Każda z działek posiada regularny kształt, idealnie nadający się do budowy domu. Działki posiadają decyzję o warunkach zabudowy oraz promesy na dostawę mediów. Nieopodal istnieje już dostęp do mediów, co znacznie przyśpiesza rozwój inwestycji. W sąsiedztwie działki wybudowane są już nowe domy.</p>',
      price: 105000,
      pricem2: 105,
      floor: '',
      rooms: 0,
      surface: 1000,
      building: 'Działka',
      market: 'Wtórny',
      year: 0,
      garage: false,
      basement: false,
      photos: [
        {
          id: '181924da-79af-4adc-b993-e3a041550df7',
          name: '1715865533031-skarpa1.jpg',
          url: 'uploads/photos/1715865533031-skarpa1.jpg',
          offerId: 'eed5d84a-6e0a-45f5-b76c-ceceabf31332'
        },
        {
          id: '64480daa-78ba-40e3-95d7-c48fff60e36e',
          name: '1715865533025-dron2.jpg',
          url: 'uploads/photos/1715865533025-dron2.jpg',
          offerId: 'eed5d84a-6e0a-45f5-b76c-ceceabf31332'
        },
        {
          id: '8363932e-af09-40bf-8166-76d1b613ea41',
          name: '1715865533027-podzial.jpg',
          url: 'uploads/photos/1715865533027-podzial.jpg',
          offerId: 'eed5d84a-6e0a-45f5-b76c-ceceabf31332'
        },
        {
          id: '8f82a3e4-fdf0-4132-a777-c4f45d3da181',
          name: '1715865532987-dolna4.jpg',
          url: 'uploads/photos/1715865532987-dolna4.jpg',
          offerId: 'eed5d84a-6e0a-45f5-b76c-ceceabf31332'
        },
        {
          id: 'fb0ce31b-7274-42a9-b812-185ab677928a',
          name: '1715865533014-dolna7.jpg',
          url: 'uploads/photos/1715865533014-dolna7.jpg',
          offerId: 'eed5d84a-6e0a-45f5-b76c-ceceabf31332'
        }
      ]
    }
  ];*/

  useEffect(() => {
    dispatch(uploadOffers());
  }, [dispatch]);

  return (
    <Container>
      <Row>
        { offers.map(offer => <OfferMiniature key={offer.id} {...offer} />) }
      </Row>
    </Container>
  )
}

export default Sell;