const initialState = '';
export const status = state => state.status;

const createActionName = actionName => `app/status/${actionName}`;
const PROCESSING = createActionName('PROCESSING');
const SUCCESS = createActionName('SUCCESS');
const CLEAR = createActionName('CLEAR');
const FAILED = createActionName('FAILED');

export const processing = payload => ({ type: PROCESSING, payload});
export const success = payload => ({ type: SUCCESS, payload});
export const failed = payload => ({ type: FAILED, payload});
export const clear = payload => ({ type: CLEAR, payload});

const statusReducer = (statePart = initialState, action) => {
  switch (action.type) {
    case PROCESSING:
      return statePart = 'processing';
    case SUCCESS:
       return statePart = 'success';
    case CLEAR:
        return statePart = '';
    case FAILED:
        return statePart = 'failed';
    default: 
      return statePart;
  }
}

export default statusReducer;