import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from './Contact.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faMapPin, faMobileScreenButton, faUserCircle, faIdCard, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faSquareFacebook } from "@fortawesome/free-brands-svg-icons";
import Maps from "../../features/Map/Map";

const Contact = () => {
  return (
    <>
      <Container className={styles['container']}>
        <h2>Zapomnij o formularzach</h2>
        <h3>Twój czas jest dla nas bardzo ważny</h3>
        <Row className={styles['header-container']}>
          <Col className={styles['image-container']} lg={3}>
            <Image src={process.env.PUBLIC_URL + '/time.png'} fluid />
          </Col>
          <Col lg={7}>
            <p>
              Dostosowujemy się do Ciebie, dlatego nie mamy sztywno określonych godzin pracy. 
              Zadzwoń, porozmawiamy a jeżeli wolisz spotkanie twarzą w twarz, to ustalimy godzinę 
              i spotkamy się przy kawie u nas w biurze. Nie masz czasu na dojazdy? 
              To też nie jest problem, wtedy my dojedziemy do Ciebie.  
            </p>
          </Col>
        </Row>
      </Container>
      <Container className={styles['contact-container']} fluid>
        <Row>
          <Col className={styles['details']} xs={12} lg={4}>
            <div className={styles['detail-container']}>
              <div className={styles['icon']}>
                <FontAwesomeIcon icon={faMobileScreenButton} />
              </div>
              <div className={styles['phone']}>
                <span>Grzegorz</span>
                <span><Link to="tel:501785913">501 785 913</Link></span>
                <span>Piotr</span>
                <span><Link to="tel:600044147">600 044 147</Link></span>
                <span>Rachela</span>
                <span><Link to="tel:519699797">519 699 797</Link></span>
              </div>
            </div>
          </Col>
          <Col className={styles['details']} xs={12} lg={4}>
            <div className={styles['detail-container']}>
              <div className={styles['icon-container']}>
              <div className={styles['icon']}>
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              </div>
              <div className={styles['email']}>
                <span>Grzegorz</span>
                <span><Link to="mailto:grzegorz@mw.nieruchomosci.pl">grzegorz@mw.nieruchomosci.pl</Link></span>
                <span>Piotr</span>
                <span><Link to="mailto:piotr@mw.nieruchomosci.pl">piotr@mw.nieruchomosci.pl</Link></span>
                <span>Rachela</span>
                <span><Link to="mailto:rachela@mw.nieruchomosci.pl">rachela@mw.nieruchomosci.pl</Link></span>
              </div>
            </div>
          </Col>
          <Col className={styles['details']} xs={12} lg={4}>
            <div className={styles['detail-container']}>
              <div className={styles['icon']}>
                <FontAwesomeIcon icon={faLocationDot} />
              </div>
              <div className={styles['address']}>
                <span>MW sp. z o.o.</span>
                <span>ul. Kombatantów 34/701</span>
                <span>66-400 Gorzów Wielkopolski</span>
                <span>Dawny Watral, teraz M34 Manhattan Business Center</span>
              </div>
            </div>
          </Col>
        </Row>

      </Container>
    </>
  )
}

export default Contact;