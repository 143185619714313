import { Card, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStairs, faBuilding, faCalendarDays, faLayerGroup, faDoorOpen, faSquare, faTrowelBricks, faVectorSquare, faPlugCircleBolt, faRulerCombined } from '@fortawesome/free-solid-svg-icons';
import styles from './OfferMiniature.module.scss';
import { API_URL } from "../../../config";
import { useNavigate  } from "react-router-dom";

const OfferMiniature = ({ id, name, price, building, floor, year, rooms, surface, description, photos, purpose, media, shape }) => {
  const navigate = useNavigate();

  return (
    <Col md={6} lg={4} className="p-0" onClick={() => navigate('/offer/' + id)}>
      <Card className={styles["card-container"] + " m-2"}>
        <Card.Img className={styles["image"]} variant="top" src={process.env.PUBLIC_URL + photos[0].url} /*src={API_URL + '/' + photos[0].url}*/ />
        <Card.Body>
            <Card.Title className={styles["title"]}>{ name }</Card.Title>
            <h6>{ price } zł</h6>
            <div className={styles["icons-container"]}>
              {
                  building !== 'Działka' 
                  ?
                  <>
                  <div className={styles["detail"]}>
                  <FontAwesomeIcon icon={faBuilding} />
                  <span>{ building }</span>
                  </div>
                  <div className={styles["detail"]}>
                    <FontAwesomeIcon icon={faStairs} />
                  <span>{ floor }</span>
                </div>
                <div className={styles["detail"]}>
                  <FontAwesomeIcon icon={faCalendarDays} />
                  <span>{ year }</span>
                </div>
                <div className={styles["detail"]}>
                  <FontAwesomeIcon icon={faLayerGroup} />
                  <span>{ surface } m2</span>
                </div>
                <div className={styles["detail"]}>
                  <FontAwesomeIcon icon={faDoorOpen} />
                  <span>{ rooms }</span>
                </div>
                </>
                :
                <>
                <div className={styles["detail"]}>
                <FontAwesomeIcon icon={faLayerGroup} />
                <span>{ building }</span>
              </div>
              <div className={styles["detail"]}>
                <FontAwesomeIcon icon={faTrowelBricks} />
                <span>{ purpose }</span>
              </div>
              <div className={styles["detail"]}>
                <FontAwesomeIcon icon={faVectorSquare} />
                <span>{ shape }</span>
              </div>
              <div className={styles["detail"]}>
                <FontAwesomeIcon icon={faRulerCombined} />
                <span>{ surface } m2</span>
              </div>
              <div className={styles["detail"]}>
                <FontAwesomeIcon icon={faPlugCircleBolt} />
                <span>{ media }</span>
              </div>
              </>

              }

            </div>
            <Card.Text dangerouslySetInnerHTML={{ __html: description}} className={styles["description"]}>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">Numer oferty: {id}</small>
          </Card.Footer>
      </Card>
    </Col>
  )
}

export default OfferMiniature;