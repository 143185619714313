import styles from './ContactBar.module.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileScreenButton, faAt, faMapPin } from '@fortawesome/free-solid-svg-icons';

const ContactBar = () => {
  return (
    <div className={styles["contact-container"]}>
      <div className={styles["contact-detail"]}><FontAwesomeIcon icon={faMobileScreenButton} /><span><Link to="tel:501785913">501 785 913</Link></span></div>
      <div className={styles["contact-detail"]}><FontAwesomeIcon icon={faAt} /><span><Link to="mailto:biuro@mw.nieruchomosci.pl">biuro@mw.nieruchomosci.pl</Link></span></div>
      <div className={styles["contact-detail"]}><FontAwesomeIcon icon={faMapPin} /><div className={styles["address"]}><span>ul. Kombatantów 34/701</span><span>66-400 Gorzów Wielkopolski</span></div></div>      
    </div>
  )
}

export default ContactBar;