import styles from './Tour.module.scss';
import { Container, Col, Row, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClapperboard, faCamera, faCheck, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import Description from '../Description/Description';

const Tour = () => {
    return (
        <>
        <div className={styles['title']}>
            <iframe src="https://evryplace.com/embed/esyyvi#262876eb-ec5d-467d-b8d9-378c86187f15" height="550" width="650" allow="microphone;fullscreen" allowfullscreen allowmicrophone ></iframe>
            <h2>Kliknij i sprawdź jak to działa!</h2>
        </div>
        <Container>
            <Row>
                <Col className={styles['dots-container']} xs={12} lg={6}>
                    <h3>Wirtualny spacer to:</h3>
                    <span><FontAwesomeIcon icon={faCheck} />Realistyczne doświadczenie zwiedzania</span>
                    <span><FontAwesomeIcon icon={faCheck} />Zwiększenie zasięgu</span>
                    <span><FontAwesomeIcon icon={faCheck} />Efektywne przyciąganie uwagi</span>
                    <span><FontAwesomeIcon icon={faCheck} />Oszczędność czasu</span>
                    <span><FontAwesomeIcon icon={faCheck} />Lepsze zrozumienie układu przestrzennego</span>
                </Col>
                <Col className={styles['info-container']} xs={12} lg={6}>
                    <div>
                        <span><FontAwesomeIcon icon={faCircleInfo} /></span>
                    </div>
                    <p>
                        Spacer wirtualny to interaktywny sposób eksploracji nieruchomości za pomocą komputera lub urządzenia mobilnego, 
                        który umożliwia potencjalnym klientom swobodne przemieszczanie się po wirtualnej przestrzeni,
                        oglądanie różnych perspektyw i odkrywanie szczegółów, podobnie jak podczas rzeczywistego spaceru, ale w środowisku cyfrowym.
                    </p>
                </Col>
            </Row>
        </Container>
        <div className={styles['promotion']}>
            <p>Sprzedajesz z nami? Zrobimy to wszystko za Ciebie i to bez dodatkowej opłaty!</p>
        </div>
        <Container>
        <h3 className={styles['how-its-made-title']}>Jak to zrobimy?</h3>
        <Row>
            <Col className={styles['how-its-made']} xs={12} lg={4}><div className={styles['number']}></div><p>Profejsonalnie przygotujemy serię zdjęć sferycznych Twojej nieruchomości.</p></Col>
            <Col className={styles['how-its-made']} xs={12} lg={4}><div className={styles['number']}></div><p>Przygotujemy komputerową obróbkę wykonanych zdjęć, gwarantującą najwyższą jakość.</p></Col>
            <Col className={styles['how-its-made']} xs={12} lg={4}><div className={styles['number']}></div><p>Tak przygotowany spacer opublikujemy. Zminimalizujemy Twoje zaangażowanie, maksymalizując zadowolenie z naszych usług!</p></Col>
        </Row>
        </Container>

        </>
    )
}

export default Tour;