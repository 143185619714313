import { useNavigate  } from "react-router-dom";
import { useState } from "react";

import { Container, Row, Col, Accordion, Image } from "react-bootstrap";
import styles from './Description.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DescriptionList from "../../features/DescriptionList/DescriptionList";
import { faLightbulb, faGraduationCap, faPersonCircleCheck, faListCheck, faBusinessTime, faHandshake } from '@fortawesome/free-solid-svg-icons';


const Description = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);

  const descritpon = [
    {
      name: "DORADZTWO",
      description: "Nie jesteś pewny, od czego zacząć? Nie martw się! Nasz zespół doradców pomoże Ci na każdym kroku kupna czy sprzedaży nieruchomości.",
      icon: faLightbulb
    },
    {
      name: "RELACJE BIZNESOWE",
      description: "Dzięki naszym relacjom z lokalnymi instytucjami i partnerami biznesowymi, jesteśmy w stanie zapewnić szybką i sprawną obsługę wszelkich formalności.",
      icon: faHandshake
    },
    {
      name: "INDYWIDUALNE PODEJŚCIE",
      description: "Niezależnie od tego, czy chcesz kupić czy sprzedać nieruchomość, nasz zespół zawsze słucha Twoich potrzeb i dostosowuje działania do Twoich oczekiwań.",
      icon: faPersonCircleCheck
    },
    {
      name: "PROFESJONALIZM",
      description: "Jesteśmy z Gorzowa Wielkopolskiego i posiadamy głęboką znajomość lokalnego rynku, co pozwala nam znaleźć najlepsze rozwiązania zarówno dla kupujących, jak i sprzedających.",
      icon: faListCheck
    },
  ]

  return (
    <>
      <Container className={styles["container"]}>
        <h2 className="d-none d-sm-block"><Image src={process.env.PUBLIC_URL + '/logo_company.png'} fluid /></h2>
        <div className={styles["description-container"]}>
          <div className={styles["description"]}>
            <Row>
              {
                descritpon.map(desc => 
                  <Col className="p-2" xs={6} xxl={3}>
                    <div className={styles["description-desktop"]}>
                      <div className={styles["description-icon"]}><FontAwesomeIcon icon={desc.icon} /></div>
                      <h3>{desc.name}</h3>{desc.description}
                    </div>
                  </Col>              
                )
              }
            </Row>
            <Accordion defaultActiveKey={['0']} alwaysOpen className="d-block d-sm-none">
              {descritpon.map(desc => <DescriptionList name={desc.name} icon={desc.icon } description={desc.description} />)}
            </Accordion>
          </div>
        </div>
      </Container>
    </> 
  )
}

export default Description;