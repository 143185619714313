import { Carousel } from "react-bootstrap";
import styles from './Home.module.scss';
import Description from "../Description/Description";
import Services from "../Services/Services";
import SocialBar from "../SocialBar/SocialBar";
import AboutUs from "../AboutUs/AboutUs";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <Carousel className={styles.carousel } fade>
        <Carousel.Item>
            <Link to="/sell">
              <img
                className="d-block w-100"
                src={process.env.PUBLIC_URL + '/podzial.jpg'}
                alt="dzialki na sprzedaz"
              />
            </Link>
          <Carousel.Caption>
            <h3>Oferty sprzedaży</h3>
            <p>Sprawdź działki Położone w Gorzowie Wielkopolskim!</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <Link to='/drones'>
              <img
                className="d-block w-100"
                src={process.env.PUBLIC_URL + '/dron2.jpg'}
                alt="ujecie z drona"
              />
            </Link>
          <Carousel.Caption>
            <h3>Fotografia dronem</h3>
            <p>Nowoczesny sprzęt, licencjonowani operatorzy drona</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <Link to="/tour">
              <img
                className="d-block w-100"
                src={process.env.PUBLIC_URL + '/sfera.png'}
                alt="zdjecie sferyczne"
              />
            </Link>  
          <Carousel.Caption className={styles['sphere']}>
            <h3>Spacery sferyczne</h3>
            <p>Nowoczesne podejście do prezentacji Twojej nieruchomości</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <Description />
      <SocialBar />
      <Services />
    </>
  )
}

export default Home;