import { Container, Row, Col } from "react-bootstrap";
import styles from './SocialBar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faSquareFacebook, faTiktok, faYoutubeSquare, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const SocialBar = () => {
  return (
    <>
    <h2>Przekonaj się jak tworzymy socialmedia</h2>
    <Container className={styles["socialbar-container"]} fluid>
      <Row className={styles["link-container"]}>
        <Link to="https://www.facebook.com/profile.php?id=61557616326122" target="_blank">
          <FontAwesomeIcon icon={faSquareFacebook} />
        </Link> 
        <Link to="https://www.tiktok.com/@mw.nieruchomoci.p" target="_blank">
          <FontAwesomeIcon icon={faTiktok} />
        </Link>
        <Link to="https://www.instagram.com/m.w.nieruchomosci/" target="_blank">
          <FontAwesomeIcon icon={faInstagram} />
        </Link>
        <Link to="https://www.instagram.com/m.w.nieruchomosci/" target="_blank">
          <FontAwesomeIcon icon={faYoutube} />
        </Link>
      </Row>
    </Container>
    </>
  )
}

export default SocialBar;