import styles from './Drones.module.scss';
import { Container, Image, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const Drones = () => {
    return (
        <>
            <Container className={styles["drones"]}>
                <Row className={styles['container']}>
                    <Col xs={12} xl={6} className={styles["image"]}>
                        <Image src={process.env.PUBLIC_URL + '/20240319-DJI_20240319150427_0064_V (1).jpg'} fluid />
                    </Col>
                    <Col xs={12} xl={6}>
                        <h2>Nieruchomość z zupełnie innej perspektywy</h2>
                        <p>
                            Prezentacja nieruchomości dronem oferuje klientom interaktywne doświadczenie, 
                            pozwalając na eksplorację z lotu ptaka. Dzięki temu mogą lepiej zrozumieć układ przestrzenny
                            i atrakcyjność nieruchomości.   
                        </p>
                        <p>
                            Dodatkowo, fotografia z drona stanowi skuteczne narzędzie marketingowe, 
                            wyróżniając ofertę na konkurencyjnym rynku i zwiększając jej widoczność online. 
                            Co ma bezpośredni wpływ na czas sprzedaży nieruchomości.
                        </p>

                    </Col>
                </Row>
                <Row className={styles['container']}>
                    <Col xs={12} xl={6} className={styles['yt-description']}>
                        <h2>Co nas wyróżnia?</h2>
                        <div><span><FontAwesomeIcon icon={faCircleCheck} /></span>Nowoczesny sprzęt</div>
                        <div><span><FontAwesomeIcon icon={faCircleCheck} /></span>Latamy legalnie</div>
                        <div><span><FontAwesomeIcon icon={faCircleCheck} /></span>Montaż i postprodukcja</div>
                        <div><span><FontAwesomeIcon icon={faCircleCheck} /></span>Doświadczenie</div>
                    </Col>
                    <Col xs={12} xl={6} className={styles["youtube"]}>
                        <Image src={process.env.PUBLIC_URL + '/dron2.jpg'} fluid />
                        {/*<iframe className={styles['embed-responsive-item']} src="https://www.youtube.com/embed/Q4-jOuHO-z4?si=75N5RTtVuftYFlSI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>*/}                
                    </Col>

                </Row>
                <Row className={styles['container']}>
                    <Col xs={12} xl={6} className={styles["image"]}>
                        <Image src={process.env.PUBLIC_URL + '/dron1.jpg'} fluid />
                    </Col>
                    <Col xs={12} xl={6}>
                        <h2>A może wideo?</h2>
                        <p>
                            Filmowanie dronem to fascynująca i dynamiczna forma tworzenia filmów, która umożliwia uzyskanie niesamowitych ujęć z wysokości.
                            Drony pozwalają uzyskać piękne, profesjonalne i atrakcyjne ujęcia, które mogą znacząco zwiększyć atrakcyjność nieruchomości dla potencjalnych klientów. 
                        </p>
                        <p>
                            Dzięki imponującym ujęciom z lotu ptaka i pięknym widokom, mogą oni lepiej poczuć atmosferę i potencjał danej nieruchomości, 
                            co może pomóc potencjalnym klientom w podjęciu decyzji o zakupie.
                        </p>
                    </Col>                  
                </Row>
            </Container>




        </>
    )
}

export default Drones;